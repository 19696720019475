import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

function Header() {
    const { i18n, t } = useTranslation();

    const toggleLanguage = () => {
        const newLang = i18n.language === 'fr' ? 'en' : 'fr';
        i18n.changeLanguage(newLang);
    };
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const allLinksPages = [
        '/EldenLore',
        '/MyAgenda',
        '/TenCents',
        '/Kasa',
        '/MonVieuxGrimoire',
        '/NinaCarducci',
        '/SophieBluel',
        '/Booki',
        '/*',
    ];
    const linksPage = allLinksPages.includes(location.pathname);
    const linksHome = location.pathname === '/';

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    ///////////////////////////SCROLL EFFECTS/////////////////////////////////////

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
    }, [isMenuOpen]);

    window.addEventListener('scroll', function () {
        let topPage = document.querySelector('.topPage');

        if (window.scrollY > 0) {
            topPage.classList.add('showTopPage');
        } else {
            topPage.classList.remove('showTopPage');
        }
    });

    window.addEventListener('scroll', function () {
        let headerLink = document.querySelectorAll('.headerLink');

        headerLink.forEach(function (headerLink) {
        if (window.scrollY > 540) {
            headerLink.classList.add('showHeaderLink');
        } else {
            headerLink.classList.remove('showHeaderLink');
        }
    })
    });

    window.addEventListener('scroll', function () {
        let header = document.querySelector('.header');

        if (window.scrollY > 0) {
            header.classList.add('showLimit');
        } else {
            header.classList.remove('showLimit');
        }
    });
    return (
        <div className="header">
            <ScrollLink
                href="topPageLien"
                to="topPageLink"
                className="topPage"
                spy={true}
                smooth={true}
                duration={800}
            >
                <span className="material-symbols-rounded topPageIcon">
                    keyboard_arrow_up
                </span>
            </ScrollLink>
            {linksPage && (
                <>
                    <RouterLink className="backHomeLink" to={`/`}>
                        <span className="material-symbols-rounded homeIcon">
                            home
                        </span>
                    </RouterLink>
                </>
            )}
            <div className="headerContent">
            <div className="headerContentDesktop">
            {linksHome && (
                                    <>
            <ScrollLink
                            href="contactLien"
                            to="contactLien"
                            className="headerLink"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >
                            <span className="material-symbols-rounded headerContact">
                                mail
                            </span>
                        </ScrollLink>
                        <a
                            className="headerLink githubHeaderLink"
                            href="https://github.com/chrischris4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="githubHeaderImg"
                                src="https://i.ibb.co/DVbScgL/icons8-github-128.png"
                                alt=""
                            />
                        </a>
                <ScrollLink
                            href="aboutLien"
                            to="aboutLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                        >
                            {t('aboutLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="parcoursLien"
                            to="parcoursLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                        >
                            {t('parcoursLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="competenceLien"
                            to="competenceLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                        >
                            {t('competenceLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="projectPersoLien"
                            to="projectPersoLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('projetsLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="servicesLien"
                            to="servicesLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                        >
                            Services
                        </ScrollLink>
                        </>
            )}
            {linksPage && (
                <>
                    <RouterLink className="link" to={`/`}>
                        Home
                    </RouterLink>
                <ScrollLink
                href="othersLien"
                to="othersLien"
                className="link"
                spy={true}
                smooth={true}
                duration={800}
                onClick={closeMenu}
            >
                {t('othersProjectsLink')}
            </ScrollLink>
            </>
            )}
                        <ScrollLink
                    href="reseauxLien"
                    to="reseauxLien"
                    className="link"
                    spy={true}
                    smooth={true}
                    duration={800}
                >
                    {t('reseauxLink')}
                </ScrollLink>
                        <div className="translateBtn" onClick={toggleLanguage}>
                    <span className="material-symbols-rounded translateIcon">
                        translate
                    </span>{' '}
                    {t('buttonText')}
                </div>
                        </div>
                <div
                    className={`headerNavIcon ${
                        isMenuOpen ? 'navIsOpen ' : ''
                    }`}
                    onClick={toggleMenu}
                >
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    <div className="line line3"></div>
                </div>
            </div>
            <div
                className={`overlayNav ${isMenuOpen ? 'showOverlayNav' : ''}`}
                onClick={closeMenu}
            ></div>
            <nav
                className={`headerNav ${isMenuOpen ? 'showNav' : ''}`}
            >
                {linksHome && (
                    <>
                        <ScrollLink
                            href="aboutLien"
                            to="aboutLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('aboutLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="parcoursLien"
                            to="parcoursLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('parcoursLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="competenceLien"
                            to="competenceLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('competenceLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="projectPersoLien"
                            to="projectPersoLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('projetPersoLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="projectLien"
                            to="projectLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('projetLink')}
                        </ScrollLink>
                        <ScrollLink
                            href="servicesLien"
                            to="servicesLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            Services
                        </ScrollLink>
                        
                    </>
                )}
                {linksPage && (
                    <>
                        <RouterLink
                            to={`/`}
                            className="link"
                            onClick={closeMenu}
                        >
                            {t('homeLink')}
                        </RouterLink>
                        <ScrollLink
                            href="othersLien"
                            to="othersLien"
                            className="link"
                            spy={true}
                            smooth={true}
                            duration={800}
                            onClick={closeMenu}
                        >
                            {t('othersProjectsLink')}
                        </ScrollLink>
                    </>
                )}
                <ScrollLink
                    href="reseauxLien"
                    to="reseauxLien"
                    className="link"
                    spy={true}
                    smooth={true}
                    duration={800}
                    onClick={closeMenu}
                >
                    {t('reseauxLink')}
                </ScrollLink>
                <ScrollLink
                    href="contactLien"
                    to="contactLien"
                    className="link"
                    spy={true}
                    smooth={true}
                    duration={800}
                    onClick={closeMenu}
                >
                    Contact
                </ScrollLink>
                <RouterLink
                    className="link"
                    to={`https://github.com/chrischris4/`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={closeMenu}
                >
                    GitHub
                </RouterLink>
                <div className="translateBtnMobile" onClick={toggleLanguage}>
                    <span className="material-symbols-rounded translateIcon">
                        translate
                    </span>
                    {t('buttonText')}
               </div> 
            </nav>
        </div>
    );
}

export default Header;
