import Banner from '../components/Banner';
import '../styles/Home.css';
import { useEffect, useState} from 'react';
import AllProject from '../components/AllProject';
import Project from '../components/Project';
import ContactForm from '../components/ContactForm';
import MetaTags from '../components/MetaTags';
import RichSnippetAuthor from '../components/RichSnippetAuthor';
import Reseaux from '../components/Reseaux';
import Services from '../components/Services';
import { useTranslation } from 'react-i18next';
import Competence from '../components/Competence';
import Parcours from '../components/Parcours';

function Home() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        const observerLeft = new IntersectionObserver(
            (entries, observerLeft) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(
                            "animateLeft"
                        );
                        observerLeft.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        const elementsToAnimateLeft = document.querySelectorAll(
            '.about h3'
        );

        elementsToAnimateLeft.forEach((element) => {
            observerLeft.observe(element);
        });

        const observerRight = new IntersectionObserver(
            (entries, observerRight) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(
                            "animateRight"
                        );
                        observerRight.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.6,
            }
        );

        const elementsToAnimateRight =
            document.querySelectorAll(' .cv');

        elementsToAnimateRight.forEach((element) => {
            observerRight.observe(element);
        });

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(
                            "animate"
                        );
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        const elementsToAnimate = document.querySelectorAll(
            '.competence, .parcours'
        );

        elementsToAnimate.forEach((element) => {
            observer.observe(element);
        });
    }, );

        const [activeAnimItems, setActiveAnimItems] = useState([]); 
        const [specialAnim, setSpecialAnim] = useState(false);

        const [finalAnim, setFinalAnim] = useState(false)
  const handleButtonClick = (itemId) => {
    if (!activeAnimItems.includes(itemId)) {
      setActiveAnimItems([...activeAnimItems, itemId]);
    }
  };

  useEffect(() => {
    if (
      ['animItem1', 'animItem2', 'animItem3'].every((item) =>
        activeAnimItems.includes(item)
      )
    ) {
      const timeout = setTimeout(() => {
        setSpecialAnim(true);
      }, 1000);
      const timeout2 = setTimeout(() => {
        setFinalAnim(true);
      }, 3000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout2);
      };
    } else {
      setSpecialAnim(false);
      setFinalAnim(false);
    }
  }, [activeAnimItems]);
          


    return (
        <div className="page-container">
            <MetaTags
                title="Portfolio - JOST Christopher"
                description="Je suis Christopher JOST, un développeur FullStack spécialisé dans la création d'applications web dynamiques. Explorez mon portfolio, mes projets et découvrez mon parcours de formation en développement web. Contactez-moi pour collaborer sur des projets innovants."
                keywords="Jost Christopher, développeur, FullStack, application, react, express, mongodb, frontend, backend, portfolio"
                author="Jost Christopher"
                og_title="Portfolio - JOST Christopher"
                og_description="Découvrez mon portfolio qui met en valeur mes projets créatifs et mes compétences en développement web."
                og_image="https://www.christopher-jost.fr/JC.ico"
                og_url="https://www.christopher-jost.fr/"
                og_type="website"
            />
            <RichSnippetAuthor />
            <div id="topPageLink"></div>
            <div id="backHome"></div>
            <Banner />
            <div id="aboutLien"></div>
            <div className="aboutAll">
                <div className="about">
                        <h2>
                            {t('titleAboutTranslate')}
                        </h2>
                        <p >{t('textUpAbout')}</p>
                        <p >{t('textAbout')}</p>
                    <h3>{t('h3AboutTranslate')}</h3>
                </div>
    
                <div className=
                {`aboutAnim ${finalAnim ? 'finalAnimOn' : ''}`}>
      <div className="aboutAnimItem" id="animItem1">
      <p
          className={`${
            activeAnimItems.includes('animItem1') ? 'animOn' : ''
          } ${specialAnim ? 'specialAnimOnRight' : ''}`}
        >Less</p>
        <div
          className={`aboutAnimBtn ${activeAnimItems.includes('animItem1') ? 'animOn' : ''}`}
          onClick={() => handleButtonClick('animItem1')}
        ></div>
      </div>

      <div className="aboutAnimItem" id="animItem2">
        <p className={activeAnimItems.includes('animItem2') ? 'animOn' : ''}>
            is</p>
        <div
          className={`aboutAnimBtn ${activeAnimItems.includes('animItem2') ? 'animOn' : ''}`}
          onClick={() => handleButtonClick('animItem2')}
        ></div>
      </div>

      <div className="aboutAnimItem" id="animItem3">
      <p
          className={`${
            activeAnimItems.includes('animItem3') ? 'animOn' : ''
          } ${specialAnim ? 'specialAnimOnLeft' : ''}`}
        >            more.</p>
        <div
          className={`aboutAnimBtn ${activeAnimItems.includes('animItem3') ? 'animOn' : ''}`}
          onClick={() => handleButtonClick('animItem3')}
        ></div>
      </div>
    </div>
            </div>
            <div id="parcoursLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('parcoursTitleTranslate')}</h2>
            </div>
            <div className="parcoursSection">
                <Parcours
                    title={t('openTitleTranslate')}
                    date="12/2022 - 08/2023"
                    p={t('openPTranslate')}
                    p2={t('openP2Translate')}
                    link="https://openclassrooms.com/fr/"
                    overlayTitle={t('overlayParcoursTranslate')}
                    overlayP="openclassrooms.com"
                    cover="https://i.ibb.co/ZzwdcZF/open-min.webp"
                    altCover="openclassroomshomepage"
                />
                <Parcours
                    title={t('adrarTitleTranslate')}
                    date="10/2021 - 06/2022"
                    p={t('adrarPTranslate')}
                    link="https://www.adrar-formation.com/"
                    overlayTitle={t('overlayParcoursTranslate')}
                    overlayP="adrar-formation.com"
                    cover="https://i.ibb.co/Q6PbSS4/adrar-min.webp"
                    altCover="adrarhomepage"
                />
            </div>
            <div className="cv">
                <a
                    className="buttonCv"
                    href="https://drive.google.com/uc?export=download&id=1Pf7efCGmqpNwOdOPCgAlWOrnsAaeK4Ph
                        "
                    download
                >
                    {t('buttonCvTranslate')}
                    <span className="material-symbols-rounded dl">
                        download
                    </span>
                </a>
            </div>
            <div id="competenceLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('competenceTitleTranslate')}</h2>
            </div>
            <div id="competences">
                <Competence
                    title="Frontend"
                    cover="https://i.ibb.co/tLWdp4Z/computer-7358692.png
"
                    list={['/assets/svg/js-svgrepo-com.svg','/assets/svg/typescript-svgrepo-com.svg', '/assets/svg/react-svgrepo-com.svg','/assets/svg/tailwind-svgrepo-com.svg']}
                />
                <Competence
                    title="Backend"
                    cover="
https://i.ibb.co/D7d9JxP/database-658099.png
"
                    list={['/assets/svg/mongodb-svgrepo-com.svg', '/assets/svg/node-svgrepo-com.svg', '/assets/svg/php-1-logo-svgrepo-com.svg', '/assets/svg/mysql-svgrepo-com.svg']}
                />
                <Competence
                    title="Outils"
                    cover="
https://i.ibb.co/tzLVdzv/support-807303.png
"
                    list={['/assets/svg/vscode-svgrepo-com.svg', '/assets/svg/postman-icon-svgrepo-com.svg', '/assets/svg/gitlab-svgrepo-com.svg', '/assets/svg/xampp-svgrepo-com.svg', '/assets/svg/figma-svgrepo-com.svg']}
                />
            </div>
            <div id="projectPersoLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('projetPersoTitle')}</h2>
            </div>
            <div id="projectsPerso">
                <div className="projectContent perso tout back front seo">
                    <Project
                        title={t('EldenLoreTitleTranslate')}
                        cover="https://i.ibb.co/z2dYpT7/elden-Lore.webp"
                        link={`/EldenLore`}
                        loading="lazy"
                        filtre="PHP"
                    />
                </div>
                <div className="projectContent perso tout back front seo">
                    <Project
                        title={t('TenCentsTitleTranslate')}
                        cover="https://i.ibb.co/BtNkKrp/ten-Cents-Mini.webp"
                        link={`/TenCents`}
                        loading="lazy"
                        filtre="React / Node.js"
                    />
                </div>
                <div className="projectContent perso tout back front seo">
                    <Project
                        title={t('MyAgenda - Votre agenda en ligne')}
                        cover="https://i.ibb.co/Pg9WvgH/myAgenda.webp"
                        link={`/MyAgenda`}
                        loading="lazy"
                        filtre="React / Node.js"
                    />
                </div>
            </div>
            <div id="projectLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('projetTitle')}</h2>
            </div>
            <AllProject />
            <div id="servicesLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>Services</h2>
            </div>
            <div className="servicesContent">
                <Services
                    title={t('webDevTitleTranslate')}
                    span="code"
                    description={t('webDevDescriptionTranslate')}
                    cover="https://i.ibb.co/0Kz4zHD/responsive-1055687.png"
                    listTitle={t('webDevListTitleTranslate')}
                    list={[
                        'HTML/CSS',
                        'React',
                        'Node.js',
                        'JavaScript',
                        'MongoDB',
                        'PHP',
                    ]}
                    listTitle2={t('webDevListTitle2Translate')}
                    list2={[
                        t('listWebTranslate'),
                        t('listWebTranslate1'),
                        t('listWebTranslate2'),
                        t('listWebTranslate3'),
                    ]}
                    p={t('servicesPTranslate')}
                    link={['/TenCents', '/EldenLore']}
                />
                <Services
                    title="SEO"
                    span="monitoring"
                    description={t('seoDescriptionTranslate')}
                    cover="https://i.ibb.co/h9j9H7t/ux.png"
                    listTitle={t('seoListTitleTranslate')}
                    list={['Pagespeed', 'Wave']}
                    listTitle2={t('seoListTitle2Translate')}
                    list2={[
                        t('listSeoTranslate'),
                        t('listSeoTranslate1'),
                        t('listSeoTranslate2'),
                        t('listSeoTranslate3'),
                    ]}
                    p={t('servicesPTranslate')}
                    link={['/NinaCarducci']}
                />
            </div>

            <div id="reseauxLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('reseauxTitleTranslate')}</h2>
            </div>
            <Reseaux />
            <div id="contactLien"></div>
            <div className="sectionTitle">
                <div className="styleTitle"></div>
                <h2>{t('contactTitleTranslate')}</h2>
            </div>
            <ContactForm />
        </div>
    );
}

export default Home;
