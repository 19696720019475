import '../styles/Competence.css';

function Competence(props) {
    return (
        <div className="competence">
            <div className='competenceTitle'>
            <h3>{props.title}</h3>
            <div className='competenceImg'>
            <img
                    src={props.cover}
                    alt="illustration frontend"
                    loading="lazy"
                />
                </div>
                </div>
                <ul>
    {props.list &&
        props.list.map((item, index) => (
            <li key={index}>
                <img className='competenceSvg' src={item} alt={`Skill ${index}`} loading="lazy" />
            </li>
        ))}
</ul>
        </div>
    );
}

export default Competence;
