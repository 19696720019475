import '../styles/Banner.css';
import { useEffect, useRef } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

function Banner() {
    const { t } = useTranslation();

    useEffect(() => {
        const linkTimeout = setTimeout(() => {
            const bannerLink = document.querySelector('.bannerLink');
            bannerLink.classList.add('show-link');
        }, 1500);

        const githubLinkTimeout = setTimeout(() => {
            const githubLink = document.querySelector('.githubLink');
            githubLink.classList.add('show-link');
        }, 1500);

        return () => {
            clearTimeout(linkTimeout);
            clearTimeout(githubLinkTimeout);
        };
    }, []);

    useEffect(() => {
        const updatePositions = () => {
            const points = document.querySelectorAll('.pulsePoint');

            points.forEach((point) => {
                const randomTop = Math.random();
                const randomLeft = Math.random();

                point.style.setProperty('--random-top', randomTop);
                point.style.setProperty('--random-left', randomLeft);
            });
        };

        updatePositions();

        const interval = setInterval(updatePositions, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const updatePositions = () => {
            const pointsDelayed =
                document.querySelectorAll('.pulsePointDelayed');

            pointsDelayed.forEach((pointDelayed) => {
                const randomTop = Math.random();
                const randomLeft = Math.random();

                pointDelayed.style.setProperty('--random-top', randomTop);
                pointDelayed.style.setProperty('--random-left', randomLeft);
            });
        };

        updatePositions();

        const interval = setInterval(updatePositions, 3300);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const updatePositions = () => {
            const pointsColor =
                document.querySelectorAll('.pulsePointColor');

            pointsColor.forEach((pointColor) => {
                const randomTop = Math.random();
                const randomLeft = Math.random();

                pointColor.style.setProperty('--random-top', randomTop);
                pointColor.style.setProperty('--random-left', randomLeft);
            });
        };

        updatePositions();

        const interval = setInterval(updatePositions, 3600);

        return () => clearInterval(interval);
    }, []);



    const bannerRef = useRef(null);
    
    const createShootingStar = () => {
        const banner = bannerRef.current;
        if (!banner) return;
    
        const star = document.createElement('div');
        star.className = 'shootingStar';
    
        // Position de départ aléatoire
        const startX = Math.random() * banner.offsetWidth;
        const startY = Math.random() * banner.offsetHeight;
    
        // Générer des translations aléatoires
        const translateX = (Math.random() - 0.5) * 800; // Aléatoire de -400px à +400px
        const translateY = (Math.random() - 0.5) * 800; // Aléatoire de -400px à +400px
    
        // Durée aléatoire pour plus de naturel
        const duration = (1).toFixed(2); // Entre 0.5s et 1.5s
    
        // Appliquer les styles dynamiques
        star.style.left = `${startX}px`;
        star.style.top = `${startY}px`;
        star.style.setProperty('--translate-x', `${translateX}px`);
        star.style.setProperty('--translate-y', `${translateY}px`);
        star.style.setProperty('--duration', `${duration}s`);
    
        // Ajouter l'étoile filante au DOM
        banner.appendChild(star);
    
        // Supprimer après l'animation
        setTimeout(() => {
            banner.removeChild(star);
        }, duration * 1000); // Supprimer après la durée de l'animation
    };
    

    useEffect(() => {
        const interval = setInterval(() => {
            if (Math.random() < 0.2) { // Probabilité de création (20%)
                createShootingStar();
            }
        }, 2000);

        return () => clearInterval(interval);
    }, []);
    
    return (
        <div className="banner">
            <div className="bannerColor">
                <div className="bannerLastName">JOST</div>
                <div className="bannerPattern">
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                    </div>
            </div>
            <div className="bannerBlack">
                <div className="bannerContent">
                    <div className="bannerFirstName">CHRISTOPHER</div>
                    <h1>{t('bannerTitleTranslate')}</h1>
                    <div className="bannerAbout">React | NodeJS</div>
                    <div className="bannerLinks">
                        <ScrollLink
                            href="contactLien"
                            to="contactLien"
                            className="bannerLink"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >
                            <span className="material-symbols-rounded bannerContact">
                                mail
                            </span>
                        </ScrollLink>
                        <a
                            className="bannerLink githubLink"
                            href="https://github.com/chrischris4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="imgContact"
                                src="https://i.ibb.co/DVbScgL/icons8-github-128.png"
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="bannerPattern" ref={bannerRef}>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePoint"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointColor"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                        <div className="pulsePointDelayed"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
